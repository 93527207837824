import React from 'react';
import classes from './corporate-tariff.module.css';

interface CorporateTariffProps {
  text: string;
  table1: {
    title: string;
    data: string[];
  }[];
  table2: {
    one_row: string[][];
    three_rows: {
      title: string;
      class1: string;
      class2: string;
      desc: string;
    }[];
  };
}

const CorporateTariff: React.FC<CorporateTariffProps> = ({text, table1, table2}) => (
  <>
    <div dangerouslySetInnerHTML={{__html: text}}></div>
    <div className="table_overflow">
      <div className={classes.unitOnTopTable}>
        <strong>грн/кВт*год</strong>
      </div>
      <table className="outline-table">
        <thead>
          <tr>
            <th rowSpan={2}>Малі непобутові споживачі</th>
            <th colSpan={3}>1 клас</th>
            <th colSpan={3}>2 клас</th>
          </tr>
          <tr>
            <th>безПДВ</th>
            <th>ПДВ 20%</th>
            <th>Разом з ПДВ</th>
            <th>безПДВ</th>
            <th>ПДВ 20%</th>
            <th>Разом з ПДВ</th>
          </tr>
        </thead>
        <tbody>
          {table1.map(tableRow => (
            <tr>
              <td>{tableRow.title}</td>
              {tableRow.data.map(dataItem => (
                <td>{dataItem}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <p>
      Розрахунок ціни на універсальні послуги здійснено на підставі даних, затверджених відповідними постановами НКРЕКП,
      а саме:
    </p>
    <div className="table_overflow">
      <table className="outline-table">
        <tbody>
          {table2.one_row.map(tableRow => (
            <tr>
              {tableRow.map(tableCell => (
                <td>{tableCell}</td>
              ))}
            </tr>
          ))}
          {table2.three_rows.map(({title, class1, class2, desc}) => (
            <>
              <tr>
                <td colSpan={3}>{title}</td>
              </tr>
              <tr>
                <td>1 клас напруги</td>
                <td>{class1}</td>
                <td rowSpan={2}>{desc}</td>
              </tr>
              <tr>
                <td>2 клас напруги</td>
                <td>{class2}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
    <p>
      {/* Розрахунки з населенням за відпущену електроенергію здійснюються за тарифами, затвердженими постановою НКРЕКП від
      26.02.2015р. № 220, зареєстрованої в Міністерстві юстиції України 02.03.2015 р. за № 235/26680. */}
    </p>
  </>
);

export default CorporateTariff;
